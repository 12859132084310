<template>
    <div>
        <v-dialog v-model="generateInvoicePopup" max-width="450px">
            <v-card>
                <v-card-title>
                    Czy na pewno chcesz wystawić fakturę?
                </v-card-title>
                <v-card-text>
                    <div v-if="error" class="error">Wystąpił błąd. Informacja dla dewelopera: {{ error }}</div>
                    <div>
                    <v-btn
                        color="green"
                        class="white--text mt-4"
                        @click.stop="generateInvoiceFinal(true)"
                        :loading="loading"
                    >
                        TAK i wyślij do klienta
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        color="orange"
                        class="white--text mb-4 mt-4"
                        @click.stop="generateInvoiceFinal(false)"
                        :loading="loading2"
                    >
                        TAK i nie wysyłaj do klienta
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        color="gray"
                        class="dark--text"
                        @click.stop="generateInvoicePopup = false"
                    >
                        anuluj
                    </v-btn>
                </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-container class="my-5">
            <v-row>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="350px"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-model="date"
                            readonly
                            v-on="on"
                            color="indigo"
                            dark
                            class="mr-5 ml-3 mb-4"
                        >
                            {{ date }}
                        </v-btn>
                    </template>

                    <v-date-picker
                        v-model="date"
                        scrollable
                        type="month"
                        @change="dateChange(date)"
                    >
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" @click="modal = false"
                            >Anuluj</v-btn
                        >
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                            >OK</v-btn
                        >
                    </v-date-picker>
                </v-dialog>
                
                <AddCost v-if="role === 'administrator'" />
            </v-row>

            <v-row v-if="role === 'administrator'">
                <v-col>
                    <v-card class="mx-auto">
                        <v-card-title>{{ calcFullRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Przychód</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calcCopyRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Koszty copywriterów</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ correctorRate }}zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Koszty korektorów</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title
                            >{{ tutorCost.toFixed(2) }}zł</v-card-title
                        >
                        <v-card-subtitle class="text-start"
                            >Koszty kurs copywritingu</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title
                            >{{ tutorCourseCost.toFixed(2) }}zł</v-card-title
                        >
                        <v-card-subtitle class="text-start"
                            >Koszty kurs korekty</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calculateIncome }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Dochód</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calcCosts }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Koszty stałe</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calcBrutto }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Zysk brutto</v-card-subtitle
                        >
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="role === 'moderator'">
                    <v-data-table
                        :headers="headers"
                        :items="tasksForMod"
                        item-key="id"
                        class="elevation-2"
                        :hide-default-footer="true"
                        :disable-pagination="true"
                        :sort-desc="false"
                        group-by="clientId.name"
                    >
                        <template v-slot:group.header="{ group, items }">
                            <v-checkbox
                                :value="areAllGroupTasksSelected(group)"
                                @change="handleGroupSelection(group, items, $event)"
                                :label="`${group}`"
                            ></v-checkbox>
                            
                        </template>
                        <template v-slot:item.clientId="{ item }">
                            {{ item.clientId ? item.clientId.name : "" }}
                        </template>

                        <template v-slot:item.email="{ item }">
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="
                                    item.clientId ? item.clientId.email : ''
                                "
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.nip="{ item }">
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="
                                    item.clientId ? item.clientId.nip : ''
                                "
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.date="{ item }">
                            {{ item.date ? item.date.substr(0, 10) : "" }}
                        </template>

                        <template v-slot:item.fullRate="{ item }">
                            {{ item.fullRate ? item.fullRate.toFixed(2) : "" }}
                        </template>

                        <template v-slot:item.paid="{ item }">
                            <v-checkbox
                                v-model="item.paid"
                                @change="updateTaskPaid(item._id, item.paid)"
                            ></v-checkbox>
                        </template>

                        <template v-slot:item.invoice="{ item }">
                            <v-checkbox
                                v-model="item.selected"
                                @change="handleTaskSelection(item, $event)"
                            ></v-checkbox>
                        </template>
                        
                    </v-data-table>
                </v-col>

                <v-col v-else>
                    <v-data-table
                        :headers="headers"
                        :items="tasks"
                        item-key="id"
                        class="elevation-2"
                        :hide-default-footer="true"
                        :disable-pagination="true"
                        :sort-desc="false"
                        group-by="clientId.name"
                    >
                        <template v-slot:group.header="{ group, items }">
                            <v-checkbox
                                :value="areAllGroupTasksSelected(group)"
                                @change="handleGroupSelection(group, items, $event)"
                                :label="`${group}`"
                            ></v-checkbox>
                            
                        </template>

                        <template v-slot:item.clientId="{ item }">
                            {{ item.clientId ? item.clientId.name : "" }}
                        </template>

                        <template v-slot:item.email="{ item }">
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="
                                    item.clientId ? item.clientId.email : ''
                                "
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.nip="{ item }">
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="
                                    item.clientId ? item.clientId.nip : ''
                                "
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.date="{ item }">
                            {{ item.date ? item.date.substr(0, 10) : "" }}
                        </template>

                        <template v-slot:item.fullRate="{ item }">
                            {{ item.fullRate ? item.fullRate.toFixed(2) : "" }}
                        </template>

                        <template v-slot:item.paid="{ item }">
                            <v-checkbox
                                v-model="item.paid"
                                @change="updateTaskPaid(item._id, item.paid)"
                            ></v-checkbox>
                        </template>

                        <template v-slot:item.invoice="{ item }">
                            <v-checkbox
                                v-model="item.selected"
                                @change="handleTaskSelection(item, $event)"
                            ></v-checkbox>
                        </template>

                    </v-data-table>
                    
                </v-col>

                <v-col class="col-4" v-if="role === 'administrator'">
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="costsHeaders"
                                :items="costs"
                                item-key="id"
                                class="elevation-2"
                                :hide-default-footer="true"
                                :disable-pagination="true"
                                :sort-desc="false"
                            >
                                <template v-slot:item.paid="{ item }">
                                    <v-checkbox
                                        v-model="item.paid"
                                        @change="
                                            updateCost(item._id, item.paid)
                                        "
                                    ></v-checkbox>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-btn
                                        color="red"
                                        class="mt-1 mb-1 mr-1 white--text"
                                        fab
                                        small
                                        @click.stop="deleteCost(item._id)"
                                    >
                                        <v-icon dark
                                            >mdi-trash-can-outline</v-icon
                                        >
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                    <v-row class="mt-3">
                        <v-col>
                            <v-btn
                                @click="showCopyCosts = !showCopyCosts"
                                class="mb-3"
                            >
                                Odśwież koszty Copywriterów
                            </v-btn>
                            <v-data-table
                                v-if="showCopyCosts"
                                :headers="copyCostsHeaders"
                                :items="
                                    getCopyAllCosts.sort((a, b) => {
                                        if (
                                            a.name.toLowerCase() <
                                            b.name.toLowerCase()
                                        ) {
                                            return -1;
                                        }
                                        if (
                                            a.name.toLowerCase() >
                                            b.name.toLowerCase()
                                        ) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                "
                                item-key="id"
                                class="elevation-2"
                                :hide-default-footer="true"
                                :disable-pagination="true"
                                :sort-desc="false"
                            >
                                <template v-slot:item.rate="{ item }">
                                    {{ item.rate.toFixed(2) }}
                                </template>

                                <template v-slot:item.paid="{ item }">
                                    <v-checkbox
                                        v-model="item.paid"
                                        @change="
                                            updatePaid(item.uid, item.paid)
                                        "
                                    ></v-checkbox>
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col>
                            <v-btn
                                @click="showKorektorCosts = !showKorektorCosts"
                                class="mb-3"
                            >
                                Odśwież koszty Korektorów
                            </v-btn>

                            <v-data-table
                                v-if="showKorektorCosts"
                                :headers="korektorCostsHeaders"
                                :items="
                                    getKorektorAllCosts.sort((a, b) => {
                                        if (
                                            a.name.toLowerCase() <
                                            b.name.toLowerCase()
                                        ) {
                                            return -1;
                                        }
                                        if (
                                            a.name.toLowerCase() >
                                            b.name.toLowerCase()
                                        ) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                "
                                item-key="id"
                                class="elevation-2"
                                :hide-default-footer="true"
                                :disable-pagination="true"
                                :sort-desc="false"
                            >
                                <template v-slot:item.rate="{ item }">
                                    {{ getFullKorektorCosts(item) }}
                                </template>

                                <template v-slot:item.paid="{ item }">
                                    <v-checkbox
                                        v-model="item.paid"
                                        @change="
                                            updatePaidKorektor(
                                                item.uid,
                                                item.paid
                                            )
                                        "
                                    ></v-checkbox>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <Transition name="slide-fade">
            <v-btn 
                v-if="selectedTasks.length > 0" 
                class="payments__generate"
                color="green white--text"
                @click="generateInvoice"
            >Wygeneruj fakturę</v-btn>
        </Transition>
        <div class="hide">
            {{ getCopyAllCosts }}
            {{ getKorektorAllCosts }}
        </div>
    </div>
</template>

<script>
import AddCost from "@/components/molecules/AddCost.vue";
import io from "socket.io-client";

const token = localStorage.getItem('user');

export default {
    data() {
        return {
            invoiceGenerator: false,
            selectedTasks: [],
            currentGroup: null,
            generateInvoicePopup: false,
            showCopyCosts: false,
            showKorektorCosts: false,
            showTutorCosts: false,
            tasks: [],
            correctorRate: 0,
            costs: [],
            tutorCost: 0,
            tutorCourseCost: 0,
            tutor: "",
            tutorCourse: "",
            clients: [],
            coursePayments: [],
            apprenticePayments: [],
            socket: io(this.url),
            headers: [
                { text: "Wystaw FV", value: "invoice", sortable: true },
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Klient", value: "clientId", sortable: true },
                { text: "Deadline", value: "date", sortable: true },
                { text: "Stawka", value: "fullRate", sortable: true },
                { text: "Znaki", value: "characters", sortable: true },
                { text: "NIP/Adres", value: "nip", sortable: true },
                { text: "Email", value: "email", sortable: true },
                { text: "FV wystawiona", value: "paid", sortable: true },
                
            ],
            costsHeaders: [
                {
                    text: "Pozycja",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Koszt", align: "left", sortable: true, value: "cost" },
                {
                    text: "FV wystawiona",
                    align: "left",
                    sortable: true,
                    value: "paid",
                },
                {
                    text: "Akcje",
                    align: "left",
                    sortable: true,
                    value: "actions",
                },
            ],
            copyCostsHeaders: [
                {
                    text: "Imię i nazwisko",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Koszt", align: "left", sortable: true, value: "rate" },
                {
                    text: "FV wystawiona",
                    align: "left",
                    sortable: true,
                    value: "paid",
                },
            ],
            korektorCostsHeaders: [
                {
                    text: "Imię i nazwisko",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Koszt", align: "left", sortable: true, value: "rate" },
                {
                    text: "FV wystawiona",
                    align: "left",
                    sortable: true,
                    value: "paid",
                },
            ],
            date: this.$route.params.month,
            modal: false,
            isCostView: false,
            loading: false,
            loading2: false,
            error: ''
        };
    },

    methods: {
        generateInvoiceFinal(send){

            this.error = '';

            if(send){
                this.loading = true;
            }else{
                this.loading2 = true;
            }

            this.$axios
                .post(`${this.url}api/invoices`, {
                    tasks: this.selectedTasks,
                    send: send
                }, { headers: { 'x-access-token': token }})
                .then(() => {
                    this.generateInvoicePopup = false;
                    this.loading = false;
                    this.loading2 = false;
                    this.selectedTasks.forEach(taskId => {
                        const task = this.tasks.find(t => t._id === taskId);
                        if (task) {
                            this.updateTaskPaid(taskId, true);
                            this.$set(task, 'paid', true);
                        }
                    });

                    this.clearPreviousGroupSelection(); 
                })
                .catch((err) => {
                    this.error = err;
                    throw Error(err);
                });
        },
        isTaskSelected(taskId) {
            return this.selectedTasks.includes(taskId);
        },
        handleTaskSelection(task, isSelected) {
            if (isSelected) {
                if (this.currentGroup && this.currentGroup !== task.clientId.name) {
                    this.clearPreviousGroupSelection(task.clientId.name);
                }
                
                this.currentGroup = task.clientId.name;
                task.selected = true; 
                this.selectedTasks.push(task._id);
            } else {
                const index = this.selectedTasks.indexOf(task._id);
                if (index > -1) {
                    this.selectedTasks.splice(index, 1);
                }
                task.selected = false;
            }
            console.log(this.selectedTasks);
        },
        clearPreviousGroupSelection(newGroupName) {
           this.tasks.forEach(task => {

                if (task.clientId.name !== newGroupName) {
                    this.$set(task, 'selected', false); 
                }
            });

            this.selectedTasks = [];
        },
        handleGroupSelection(groupName, items, isSelected) {

        console.log(groupName, items)
        if (isSelected) {
            this.clearPreviousGroupSelection(groupName);

            items.forEach(item => {
            this.$set(item, 'selected', true); 
            if (!this.selectedTasks.includes(item._id)) {
                this.selectedTasks.push(item._id);
            }
            });
        } else {
            // Jeśli odznaczamy grupę, odznaczamy wszystkie elementy w grupie
            items.forEach(item => {
            this.$set(item, 'selected', false); // Zapewniamy reaktywność
            const index = this.selectedTasks.indexOf(item._id);
            if (index > -1) {
                this.selectedTasks.splice(index, 1);
            }
            });
        }

        // Aktualizujemy obecną grupę tylko w przypadku zaznaczenia
        if (isSelected) {
            this.currentGroup = groupName;
        }
        },
        areAllGroupTasksSelected(groupName) {
            return this.tasks.filter(task => task.clientId.name === groupName).every(task => this.selectedTasks.includes(task._id));
        },
        generateInvoice(){
            this.generateInvoicePopup = true;
        },
        getFullKorektorCosts(item){
            let data = this.date.split("-");
            let year = parseInt(data[0]);
            let month = parseInt(data[1]);

            // let currentDate = new Date();
            // let day = currentDate.getDate(); 

            let email = item.email;
            let full = 0;

            // console.log('appre', this.apprenticePayments[email]);
            console.log('course', this.coursePayments[email]);
            

            if(this.coursePayments[email]){
                if (year > 2024 || (year === 2024 && month >= 2)){
                    full +=
                        (parseFloat(this.coursePayments[email]) / 1000) * 7.5;
                }
                else if (year >= 2023) {
                    full +=
                        (parseFloat(this.coursePayments[email]) / 1000) * 5.5;
                } else if (year >= 2022 && month >= 10) {
                    full +=
                        (parseFloat(this.coursePayments[email]) / 1000) * 5.5;
                } else if(year >= 2022 && month >= 4) {
                    full +=
                        (parseFloat(this.coursePayments[email]) / 1000) * 3;
                } else {
                    full +=
                        (parseFloat(this.coursePayments[email]) / 1000) * 2;
                }
            }

            if(this.apprenticePayments[email]){
                if (year > 2024 || (year === 2024 && month >= 2)){
                    full +=
                        (parseFloat(this.apprenticePayments[email]) / 1000) * 7.5;
                }
                else if (year >= 2023) {
                    full +=
                        (parseFloat(this.apprenticePayments[email]) / 1000) * 5.5;
                } else if (year >= 2022 && month >= 10) {
                    full +=
                        (parseFloat(this.apprenticePayments[email]) / 1000) * 5.5;
                } else if(year >= 2022 && month >= 4) {
                    full +=
                        (parseFloat(this.apprenticePayments[email]) / 1000) * 3;
                } else {
                    full +=
                        (parseFloat(this.apprenticePayments[email]) / 1000) * 2;
                }
            }


            // if(this.coursePayments[email]){
            //     if (year > 2024 || (year === 2024 && month >= 2)) {
            //         full += (parseFloat(this.coursePayments[email]) / 1000) * 7.5;
            //     } else if (year === 2023) {
            //         full += (parseFloat(this.coursePayments[email]) / 1000) * 5.5;
            //     } else if (year === 2022 && month >= 10) {
            //         full += (parseFloat(this.coursePayments[email]) / 1000) * 5.5;
            //     } else if(year === 2022 && month >= 4) {
            //         full += (parseFloat(this.coursePayments[email]) / 1000) * 3;
            //     } else {
            //         full += (parseFloat(this.coursePayments[email]) / 1000) * 2;
            //     }
            // }

            // if(this.apprenticePayments[email]){
            //     if (year > 2024 || (year === 2024 && month >= 2)) {
            //         full += (parseFloat(this.apprenticePayments[email]) / 1000) * 7.5;
            //     } else if (year === 2023) {
            //         full +=
            //             (parseFloat(this.apprenticePayments[email]) / 1000) * 5.5;
            //     } else if (year >= 2022 && month >= 10) {
            //         full +=
            //             (parseFloat(this.apprenticePayments[email]) / 1000) * 5.5;
            //     } else if(year >= 2022 && month >= 4) {
            //         full +=
            //             (parseFloat(this.apprenticePayments[email]) / 1000) * 3;
            //     } else {
            //         full +=
            //             (parseFloat(this.apprenticePayments[email]) / 1000) * 2;
            //     }
            // }



            // console.log("full2 "+full);

            // full += this.coursePayments[0].charactersSum;
            // if (item.email == this.tutor){
            //     full += this.tutorCost;
            // }
            // if (item.email == this.courseTutor){
            //     full += this.tutorCourseCost;
            // }
            full += item.rate;
            full = full.toFixed(2);

            // console.log('rate   '+item.rate);
            return full;
        },
        calcCorrectorRate() {
            let result = [];

            result = this.getKorektorAllCosts.reduce(function (
                previousValue,
                currentValue
            ) {
                return previousValue + currentValue.rate;
            },
            0);

            this.correctorRate = result.toFixed(2);
        },
        getPosts() {
            this.$axios
                .get(`${this.url}api/tasks/payments/${this.date}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.tasks = resp.data;
                    this.getKorektorAllCosts;
                });
        },
        getCoursePayments() {
            this.$axios
                .get(`${this.url}api/course/allcoursepayments/${this.date}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.coursePayments = resp.data;
                });
        },
        getApprenticePayments() {
            this.$axios
                .get(`${this.url}api/apprentice/allapprenticepayments/${this.date}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.apprenticePayments = resp.data;
                });
        },
        getAllAppretices() {
            this.$axios
                .get(`${this.url}api/apprentice/payment/${this.date}`, { headers: { 'x-access-token': token }})
                .then((el) => {
                    let data = this.date.split("-");
                    let month = parseInt(data[1]);
                    let year = parseInt(data[0]);

                    // let currentDate = new Date();
                    // let day = currentDate.getDate(); 

                    if (year > 2024 || (year === 2024 && month >= 2)){
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 7.5;
                    }
                    else if (year >= 2023) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if (year >= 2022 && month >= 10) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if(year >= 2022 && month >= 4) {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 3;
                    } else {
                        this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 2;
                    }

                    
                    // if (year > 2024 || (year === 2024 && month >= 2)) {
                    //     this.tutorCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 7.5;
                    // } else if (year === 2023) {
                    //     this.tutorCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    // } else if (year >= 2022 && month >= 10) {
                    //     this.tutorCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    // } else if(year >= 2022 && month >= 4) {
                    //     this.tutorCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 3;
                    // } else {
                    //     this.tutorCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 2;
                    // }

                });
        },
        getAllCourses() {
            this.$axios
                .get(`${this.url}api/course/payment/${this.date}`, { headers: { 'x-access-token': token }})
                .then((el) => {
                    let data = this.date.split("-");
                    let month = parseInt(data[1]);
                    let year = parseInt(data[0]);

                    // let currentDate = new Date();
                    // let day = currentDate.getDate();

                    if (year >= 2024 && month >= 2) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 7.5;
                    }
                    else if (year >= 2023) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if (year >= 2022 && month >= 10) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    } else if(year >= 2022 && month >= 4) {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 3;
                    } else {
                        this.tutorCourseCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 2;
                    }


                    // if (year > 2024 || (year === 2024 && month >= 2)) {
                    //     this.tutorCourseCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 7.5;
                    // } else if (year === 2023) {
                    //     this.tutorCourseCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    // } else if (year >= 2022 && month >= 10) {
                    //     this.tutorCourseCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 5.5;
                    // } else if(year >= 2022 && month >= 4) {
                    //     this.tutorCourseCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 3;
                    // } else {
                    //     this.tutorCourseCost =
                    //         (parseFloat(el.data.charactersSum) / 1000) * 2;
                    // }

                });
        },
        getTutor() {
            this.$axios
                .get(`${this.url}api/users/practice-tutor`, { headers: { 'x-access-token': token }})
                .then((response) => {
                    this.$axios
                        .get(
                            `${this.url}api/apprentice/current-tutor/${response.data.email}`, { headers: { 'x-access-token': token }}
                        )
                        .then((el) => {
                            if (el.data) {
                                if (el.data.tutor) {
                                    this.tutor = el.data.tutor.email;
                                }
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCourseTutor() {
            this.$axios
                .get(`${this.url}api/users/course-tutor`, { headers: { 'x-access-token': token }})
                .then((response) => {
                    this.$axios
                        .get(
                            `${this.url}api/course/current-tutor/${response.data.email}`, { headers: { 'x-access-token': token }}
                        )
                        .then((el) => {
                            if (el.data) {
                                if (el.data.tutor) {
                                    this.courseTutor = el.data.tutor.email;
                                }
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getCosts() {
            this.$axios
                .get(`${this.url}api/costs/${this.date}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.costs = resp.data;
                });
        },
        updateCost(itemId, itemPaid) {
            console.log("updatecost")
            this.$axios
                .put(`${this.url}api/costs`, {
                    id: itemId,
                    paid: itemPaid,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    throw Error(err);
                });
        },
        updateTaskPaid(itemId, itemPaid) {
            this.$axios
                .patch(`${this.url}api/tasks/paid`, {
                    id: itemId,
                    paid: itemPaid,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    throw Error(err);
                });
        },
        deleteCost(id) {
            this.$axios.delete(`${this.url}api/costs/${id}`, { headers: { 'x-access-token': token }}).catch((error) => {
                throw error.response.data;
            });
        },
        // New
        dateChange(date) {
            this.$router.push(`../../${this.role}/payments/${date}`);
            this.getPosts();
            this.getCosts();
            this.getAllAppretices();
            this.getAllCourses();
            this.getTutor();
            this.getCourseTutor();
            this.getCoursePayments();
            this.getApprenticePayments();

            window.setInterval(() => {
                if (this.tasks.length > 0) {
                    this.showCopyCosts = true;
                    this.showKorektorCosts = true;
                    this.calcCorrectorRate();
                }
            }, 3000);
        },
        updatePaid(uid, paid) {
            this.$axios
                .put(`${this.url}api/paids`, {
                    uid: uid,
                    paid: paid,
                    date: this.date,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
        updatePaidKorektor(uid, paid) {
            this.$axios
                .put(`${this.url}api/paids`, {
                    uid: uid,
                    paid: paid,
                    date: this.date,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getPosts();
        this.getCosts();
        this.getAllAppretices();
        this.getAllCourses();
        this.getTutor();
        this.getCourseTutor();
        this.getCoursePayments();
        this.getApprenticePayments();

        this.socket.on("newTaskAdded", () => {
            this.getPosts();
        });

        this.socket.on("taskPaidUpdate", () => {
            this.getPosts();
        });

        this.socket.on("newCostAdded", () => {
            this.getCosts();
        });

        this.socket.on("costUpdated", () => {
            this.getCosts();
        });

        this.socket.on("costDeleted", () => {
            this.getCosts();
        });

        this.socket.on("taskDeleted", () => {
            this.getPosts();
        });
        this.socket.on("newTaskAprrenticeEdited", () => {
            this.getPosts();
            this.getAllAppretices();
        });
        this.socket.on("appreticeChangedStatus", () => {
            this.getPosts();
            this.getAllAppretices();
        });

        this.socket.on("newTaskCourseEdited", () => {
            this.getPosts();
            this.getAllCourses();
        });
        this.socket.on("courseChangedStatus", () => {
            this.getPosts();
            this.getAllCourses();
        });

        window.setInterval(() => {
            if (this.tasks.length > 0) {
                this.showCopyCosts = true;
                this.showKorektorCosts = true;
                this.calcCorrectorRate();
            }
        }, 3000);
    },
    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        mailModerator() {
            return this.$store.getters.getUserMail;
        },
        calcAllKorektor() {
            // Get all task
            const allTasks = this.tasks;
            const tbl = [];
            // All tasks loop that throws korektor id
            allTasks.forEach((item) => {
                if (item.korektorId != null) {
                    if (!tbl.includes(item.korektorId._id)) {
                        tbl.push(item.korektorId._id);
                    }
                }
            });

            const sumItems = (id) => {
                let results = {
                    rate: 0,
                    name: "",
                    typo: 0,
                    paid: false,
                    uid: "",
                    email: "",
                    num: 0,
                };

                const filtredTasks = allTasks.filter((i) => {
                    if (i.korektorId != null) {
                        return i.korektorId._id === id;
                    }
                });

                filtredTasks.forEach((el) => {
                    // new rate for corrector
                    if (el.corectorRate) {
                        results.rate +=
                            (el.characters / 1000) * el.corectorRate;
                    } else {
                        results.rate += el.characters / 1000;
                    }
                    // end new rate
                    results.typo += el.characters;
                    results.name = el.korektorId.name;
                    results.uid = el.korektorId._id;
                    results.email = el.korektorId.email;
                    results.paid = false;
                    results.num += 1;
                });

                return results;
            };

            let result = [];

            

            tbl.forEach((item) => {
                result.push(sumItems(item));
            });

            console.log(result);

            return result;
        },
        calcAllCopy() {
            // Get all task
            const allTasks = this.tasks;
            const tbl = [];

            // All tasks loop that throws copywriters id
            allTasks.forEach((item) => {
                if (item.copywriterId != null) {
                    if (!tbl.includes(item.copywriterId._id)) {
                        tbl.push(item.copywriterId._id);
                    }
                }
            });

            const sumItems = (id) => {
                return allTasks
                    .filter((i) => {
                        if (i.copywriterId != null) {
                            return i.copywriterId._id === id;
                        }
                    })
                    .reduce(
                        (a, b) => {
                            if (b.copyLate) {
                                return {
                                    rate:
                                        a.rate +
                                        b.copyRate -
                                        parseFloat(b.copyLate) * 50,
                                    name: b.copywriterId.name,
                                    uid: id,
                                    paid: false,
                                };
                            } else {
                                return {
                                    rate: a.rate + b.copyRate,
                                    name: b.copywriterId.name,
                                    uid: id,
                                    paid: false,
                                };
                            }
                        },
                        { rate: 0, name: "", paid: false }
                    );
            };

            let result = [];

            tbl.forEach((item) => {
                result.push(sumItems(item));
            });
            return result;
        },
        getTutorAllCosts() {
            return [
                {
                    rate: this.tutorCost,
                    name: this.tutor.name,
                    uid: this.tutor.id,
                },
            ];
        },
        // calcKorektorAllCosts() {
        //     let arr = this.getKorektorAllCosts;
        //     let result = arr.reduce(function (previousValue, currentValue) {
        //         return previousValue + currentValue.rate;
        //     }, 0);

        //     return result.toFixed(2);
        // },
        getKorektorAllCosts() {
            let fullArray = this.calcAllKorektor;
            let arr = [];
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`, { headers: { 'x-access-token': token }})
                    .then((resp) => {
                        if (resp.data == null) {
                            this.$axios.post(`${this.url}api/paids/`, {
                                uid: item.uid,
                                paid: false,
                                date: this.date,
                                email: item.email,
                            }, { headers: { 'x-access-token': token }});
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`, { headers: { 'x-access-token': token }})
                    .then((resp) => {
                        if (resp.data != null) {
                            arr.push({
                                uid: item.uid,
                                name: item.name,
                                paid: resp.data.paid ? resp.data.paid : false,
                                rate: item.rate,
                                email: item.email,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });

            return arr;
        },
        tasksForMod(){
            return this.tasks.filter((item) => {
                return item.pmId.email == this.mailModerator
            })
        },  
        getCopyAllCosts() {
            let fullArray = this.calcAllCopy;
            let arr = [];
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`, { headers: { 'x-access-token': token }})
                    .then((resp) => {
                        if (resp.data == null) {
                            this.$axios.post(`${this.url}api/paids/`, {
                                uid: item.uid,
                                paid: false,
                                date: this.date,
                            }, { headers: { 'x-access-token': token }});
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`, { headers: { 'x-access-token': token }})
                    .then((resp) => {
                        if (resp.data != null) {
                            arr.push({
                                uid: item.uid,
                                name: item.name,
                                paid: resp.data.paid ? resp.data.paid : false,
                                rate: item.rate,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });

            return arr;
        },
        calcCopyRate() {
            let allTasks = this.tasks;
            let result = [];

            result = allTasks.reduce(function (previousValue, currentValue) {
                if (currentValue.copyLate) {
                    return (
                        previousValue +
                        currentValue.copyRate -
                        parseFloat(currentValue.copyLate) * 50
                    );
                }
                return previousValue + currentValue.copyRate;
            }, 0);

            return result.toFixed(2);
        },
        calcFullRate() {
            let allTasks = this.tasks;
            let result = [];

            result = allTasks.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.fullRate;
            }, 0);

            return result.toFixed(2);
        },
        calcCosts() {
            let allCosts = this.costs;
            let result = [];

            result = allCosts.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.cost;
            }, 0);

            return result.toFixed(2);
        },
        calculateIncome() {
            return (
                this.calcFullRate -
                this.calcCopyRate -
                this.correctorRate -
                this.tutorCost - 
                this.tutorCourseCost
            ).toFixed(2);
        },
        calcMarginNetto() {
            let value = 0;
            value = this.calcFullRate / this.calculateIncome;

            return value.toFixed(2);
        },
        calcMarginBrutto() {
            let value = 0;
            value = this.calcFullRate / this.calculateIncome;

            return value.toFixed(2);
        },
        calcBrutto() {
            return (
                this.calcFullRate -
                this.calcCopyRate -
                this.calcCosts -
                this.correctorRate -
                this.tutorCost - 
                this.tutorCourseCost
            ).toFixed(2);
        },
    },
    components: {
        AddCost,
    },
    watch: {
        selectedTasks(){
            if(this.selectedTasks.length > 0) {
                this.invoiceGenerator = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.error{
    color: red;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.hide {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 0;
    height: 0;
}

.payments{
    &__generate{
        position: fixed;
        bottom: 30px;
        right: 30px;
    }
}

::v-deep .v-input--checkbox{
    padding: 0 26px 0px 26px !important;
}
</style>

